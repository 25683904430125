<template>
  <div class="reward-activity-type-block">
    <p class="card-title">回饋種類設定</p>
    <BaseElForm ref="formRef" label-position="top" :model="formData" :rules="formRules">
      <el-alert
        v-show="!rewardTypeOptions.length"
        title="提醒："
        type="warning"
        show-icon
        center
        :closable="false"
      >
        <p>此店家目前無開啟回饋獎勵相關的模組，因此無法設定回饋種類。 如有相關需求請與歐巴小幫手聯繫，以取得協助。</p>
      </el-alert>
      <BaseElRadioGroup v-if="rewardTypeOptions.length" v-model="formData.type" :disabled="!!activityId" class="flex flex-col gap-5" @change="changeTypeHandler">
        <div
          v-for="(type,index) in rewardTypeOptions"
          :key="index"
        >
          <BaseElRadio :label="type.value">
            {{ type.label }}
          </BaseElRadio>
        </div>
      </BaseElRadioGroup>

      <BaseElFormItem v-if="formData.type === 'POINT'" label="選擇點數" prop="shopPointKey" class="pt-[20px]">
        <BaseDataSelect
          :value.sync="formData.shopPointKey"
          :disabled="!!activityId"
          placeholder="請選擇點數"
          objKey="key"
          :dataList="shopPointList"
        />
      </BaseElFormItem>
    </BaseElForm>
  </div>
</template>

<script>
import { defineComponent, reactive, ref, onMounted, nextTick, computed, watch } from 'vue'
import { noEmptyRules } from '@/validation'
import { get, filter } from 'lodash'
import { usePermissions } from '@/use/permissions'
import BaseDataSelect from '../Select/BaseDataSelect.vue'
import { useShop } from '@/use/shop'

export default defineComponent({
  name: 'RewardActivityTypeBlock',
  components: {
    BaseDataSelect,
  },
  props: ['FormsContext', 'typeSettingConfig', 'changeTypeHandler', 'activityData'],
  setup (props) {
    const { checkAction } = usePermissions()
    const { shopPointList } = useShop()
    const formRef = ref(null)
    const activityId = computed(() => get(props.activityData, 'id'))
    const rewardTypeOptions = computed(() => {
      if (!activityId.value) return filter(props.typeSettingConfig, (option) => checkAction(option.featureKey))
      return props.typeSettingConfig
    })
    const formData = reactive({
      type: null,
      shopPointKey: null,
    })
    const formRules = {
      type: [noEmptyRules()],
      shopPointKey: [noEmptyRules()],
    }

    const syncData = () => {
      const data = props.activityData
      formData.type = data.rewardType
      formData.shopPointKey = { key: data.shopPointKey }
    }

    const compactData = computed(() => {
      const data = {
        rewardType: get(formData, 'type'),
        shopPointKey: get(formData, 'shopPointKey'),
      }
      if (activityId.value || data.rewardType === 'CASHBACK') delete data.shopPointKey
      return { ...data }
    })

    const setDefaultOption = () => {
      formData.type = rewardTypeOptions.value[0].value
    }

    onMounted(async () => {
      await nextTick()
      props.FormsContext.setFormRef('type', formRef.value)
      props.FormsContext.setFormData('type', { ...compactData.value })

      if (get(props.activityData, 'id')) syncData()
      else setDefaultOption()
    })

    watch(formData, () => {
      props.FormsContext.setFormData('type', { ...compactData.value })
    })

    return {
      formData,
      formRules,
      formRef,
      activityId,
      checkAction,
      rewardTypeOptions,
      compactData,
      shopPointList,
    }
  },
})
</script>
